<template>
  <b-card
    class="presalesreturnbounditem-edit-wrapper"
  >
    <!-- form -->
    <b-form id="presalesreturnbounditemForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="前置退货单ID"
            label-for="returnbound_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="returnbound_id"
              size="sm"
              v-model="presalesreturnbounditem.returnbound_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="前置退货单编号"
            label-for="returnbound_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="returnbound_no"
              size="sm"
              v-model="presalesreturnbounditem.returnbound_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="presalesreturnbounditem.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="presalesreturnbounditem.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品编码(69码)"
            label-for="code"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="code"
              size="sm"
              v-model="presalesreturnbounditem.code"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品规格"
            label-for="specification"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="specification"
              size="sm"
              v-model="presalesreturnbounditem.specification"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="退货价"
            label-for="return_price"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="return_price"
              size="sm"
              v-model="presalesreturnbounditem.return_price"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="退货数量"
            label-for="return_qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="return_qty"
              size="sm"
              v-model="presalesreturnbounditem.return_qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="小计"
            label-for="subtotal"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="subtotal"
              size="sm"
              v-model="presalesreturnbounditem.subtotal"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="presalesreturnbounditem.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="备注"
            label-for="memo"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="memo"
              size="sm"
              v-model="presalesreturnbounditem.memo"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import presalesreturnbounditemStore from './presalesreturnbounditemStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      presalesreturnbounditem: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('presalesreturnbounditem')) store.registerModule('presalesreturnbounditem', presalesreturnbounditemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('presalesreturnbounditem')) store.unregisterModule('presalesreturnbounditem')
    })

    const edit = function() {
      store.dispatch('presalesreturnbounditem/edit', {id: this.id}).then(res => {
        this.presalesreturnbounditem = res.data.data
      })
    }

    const view = function() {
      store.dispatch('presalesreturnbounditem/view', {id: this.id}).then(res => {
        this.presalesreturnbounditem = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('presalesreturnbounditem/save', this.presalesreturnbounditem).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>